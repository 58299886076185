body {
  margin: 0;
  font-family: 'Inter Variable', sans-serif;
-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1.amplify-heading--1,
h2.amplify-heading--2,
h3.amplify-heading--3,
h4.amplify-heading--4 {
  font-family: basic-sans, sans-serif;
}
.amplify-button--primary {
  --amplify-components-button-primary-background-color: rgb(63, 117, 255);
  --amplify-components-button-primary-hover-background-color: #0142CA;
}

.amplify-button--link {
  --amplify-components-button-link-color: rgb(34, 56, 129);
}
